.Plan{
    .bgimg{
        max-height: 350px;
        background-clip:content-box;
        // position: relative;
        .text{
            font-size: 28px;
            font-weight: bold;
            // position: absolute;
            // top: 0;
        }
    }
    .info_box{
        .title{
            font-size: 20px;
        }
        .title1{
            font-size: 28px;
            padding: 10px 0;
            // line-height: 50px;
            text-align: center;
        }

        .items{
            // text-align: center;
            background: #f0f0f0;
            margin: 5px;
            height: 150px;
            display: flex;
            .icon{
                // font-size: 36px;
                margin:  0 30px;
                display: flex;
                align-items: center;
            }
            .text_box{
                padding: 10px;
                padding-left: 0;
                .text1{
                    // text-align: center;
                    font-size: 20px;
                }
                .text2{

                }
            }

        }

        .box{
            margin-bottom: 20px;
            // line-height: 40px;
        }
        .img{
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
        }
    }
}





















// .Plan1{
//     .bgimg{
//         max-height: 350px;
//         background-clip:content-box;
//         // position: relative;
//         .text{
//             font-size: 28px;
//             font-weight: bold;
//             // position: absolute;
//             // top: 0;
//         }
//     }
//     .info_box{
//         .title{
//             font-size: 20px;
//         }
//         .title1{
//             font-size: 20px;
//             padding: 10px 0;
//             // line-height: 50px;
//         }

//         .box{
//             margin-bottom: 20px;
//             line-height: 40px;
//         }
//         .img{
//             display: flex;
//             justify-content: center;
//             margin-bottom: 30px;
//         }
//     }
// }