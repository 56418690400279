@import '../../styles/theme.scss';

.Introduce{
    .info_box{
        padding-top: 30px;
        .img_box{
            padding: 30px;
            max-width:526px;
            // height:350px
        }
        .right{
            display: flex;
            align-items: center;
            padding: 30px;
            .title{
                font-size: 30px;
                font-weight: bold;
                color: $theme_color;
                margin-bottom: 30px;
                border-bottom: #ff9c00 5px solid;
                padding-bottom: 5px;
                width: 200px;
            }
            // .title_s{
            //     font-size: 18px;
            //     color: #ff9c00;
            //     margin-bottom: 30px;
            // }
            .text{
                text-indent:35px;
                line-height: 40px;
    
            }
        }

    }
    a:link {color: #000} /* 未访问的链接 */
    a:visited {color: #000} /* 已访问的链接 */
    a:hover {color: #000} /* 当有鼠标悬停在链接上 */
    a:active {color: #000} /* 被选择的链接 */
}
