@import '../../styles/theme.scss';

.Home{
    .carousel_img{
        // max-height: 350px;
        // max-height: 650px;
        // background: blue;
        color: #fff;
        // line-Height: 350px;
        text-Align: center;
        // background: $theme_color;
    }

    .product_box1{
        margin-top: 30px;
        padding: 30px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .top{
            display: flex;
            justify-content: center;
            color: $theme_color;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 30px;
            // min-width: 200px;
            text-align: center;
            // border-bottom: #dfc338 3px solid;
            position: relative;
            padding-bottom: 15px;
        }
        .line{
            height: 2px;
            background: #dfc338;
            width: 300px;
            position: absolute;
            bottom: 0;
        }
        .info{
            // display: flex;
            // justify-content: center;
            // padding: 0 50px;
            .items{
                width: 100%;
                display: flex;
                margin: 7.5px;
                background: #f0f0f0;
                .pic_box{
                    font-size: 0px;
                    width: 45%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    .img{
                        max-height: 250px;
                        max-width: 250px;
                        transition: all 0.6s ease 0s;
                    }
                    // .img:hover{
                    //     transform: scale(1.2);
                    //     /*transition: all 2s ease-in-out 0s;*/
                    // }
                }
                .text_box{
                    // background: #f0f0f0;
                    // visibility:hidden;
                    // opacity:0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // box-shadow:0 0 15px #ccc;
                    .t_box{
                        text-align: center;
                    }
                    .title{
                        // width: 200px;
                        font-size: 22px;
                        font-weight: bold;
                        // text-align: center;
                        // margin-bottom: 15px;
                        padding-bottom: 8px;
                        border-bottom: #dfc338 4px solid;
                    }
                    .text{
                        padding-top: 8px;
                        color: #999;
                        text-align: center;
                    }
                    .btn{
                        // position: absolute;
                        right: 60px;
                        margin-top: 10px;
                        // border-bottom: #909090 1px solid;
                    }
                    .line{
                        height: 4px;
                        width: 200px;
                        background: #f5de6b;
                    }
    
                }
                // .text_box:hover{
                //     // -moz-box-shadow:0 0 10px #f0f0f0;
                //     // -webkit-box-shadow:0 0 10px #f0f0f0;
                //     box-shadow:0 0 15px #ccc;
                //     background: #fff;
                //     // border: 1px #f0f0f0 solid;
                // }

            }
            // .text_box:hover{
            //     opacity:1;
            //     // -moz-box-shadow:0 0 10px #f0f0f0;
            //     // -webkit-box-shadow:0 0 10px #f0f0f0;
            //     box-shadow:0 0 15px #ccc;
            //     background-color:rgba(255,255,255,0.9);
            //     // border: 1px #f0f0f0 solid;
            //     .text{
            //         color: #999;
            //     }

            // }
            
            // .items:first-child{
            //     margin-right: 15px;
            // }
        }
        
    }

    .product_box{
        margin-top: 30px;
        padding: 30px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .top{
            color: $theme_color;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 30px;
        }
        .info{
            // display: flex;
            // justify-content: center;
            // padding: 0 50px;
            .items{
                width: 100%;
                .pic_box{
                    font-size: 0px;
                    width: 100%;
                    // background: red;
                    overflow: hidden;
                    .img{
                        // height: 100%;
                        transition: all 0.6s ease 0s;
                    }
                    .img:hover{
                        transform: scale(1.2);
                        /*transition: all 2s ease-in-out 0s;*/
                    }
                }
                .text_box{
                    // background: #f0f0f0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow:0 0 15px #ccc;
                    .t_box{
                        text-align: center;
                    }
                    .title{
                        // width: 200px;
                        font-size: 24px;
                        font-weight: bold;
                        text-align: center;
                        // margin-bottom: 15px;
                        padding-bottom: 8px;
                        border-bottom: #988a42 4px solid;
                    }
                    .text{
                        padding-top: 8px;
                        color: #909090;
                        text-align: center;
                    }
                    .btn{
                        position: absolute;
                        right: 60px;
                        margin-top: 10px;
                        border-bottom: #909090 1px solid;
                    }
                    .line{
                        height: 4px;
                        width: 200px;
                        background: #988a42;
                    }
    
                }
                // .text_box:hover{
                //     // -moz-box-shadow:0 0 10px #f0f0f0;
                //     // -webkit-box-shadow:0 0 10px #f0f0f0;
                //     box-shadow:0 0 15px #ccc;
                //     background: #fff;
                //     // border: 1px #f0f0f0 solid;
                // }

            }
            
            // .items:first-child{
            //     margin-right: 15px;
            // }
        }
    }

    .plan_box{
        // max-height: 600px;
        // background: #a7cbe9;
        // background: #f0f0f0;
        box-shadow:0 0 15px #ccc;
        background-color:rgba(255,255,255,0.9);
        margin-top: 30px;
        padding: 30px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .top{
            display: flex;
            justify-content: center;
            color: $theme_color;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 10px;
            // min-width: 200px;
            text-align: center;
            // border-bottom: #dfc338 3px solid;
            position: relative;
            // padding-bottom: 15px;
        }
        .line{
            height: 2px;
            background: #dfc338;
            width: 300px;
            position: absolute;
            bottom: 0;
        }
        .top_text{
            text-align: center;
        }
        .info{
            padding: 30px 0;
                
            display: flex;
            justify-content: center;
            .info_{
                // width: 900px;
                .img{
                    max-width: 980px;
                }
                .text_box{
                    text-align: center;
                    width: 100%;
                }
            }
            .items:first-child{
                margin-right: 15px;
            }
            .items{
                // height: 370px;
                // text-align: center;
                .img{
                    max-width: 980px;
                }
                .text_box{
                    line-height: 28px;
                    height: 200px;
                    // text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .btn_box{
                    // width: 80%;
                    // text-align: end;
                    position: absolute;
                    bottom: 30px;
                    .btn{
                        text-align: center;
                        background: $theme_color;
                        color: #fff;
                    }
                }

            }
        }
    }
    
    .info_box{
        // display: flex;
        padding: 30px;
        .col_box{
            display: flex;
            justify-content: center;
        }
        .box{
            background: #f0f0f0;
            text-align: center;
            margin: 15px;
            // width:200px;
            max-width: 405px;
            border-radius: 15px;
            .img_box{
                max-width: 405px;
                max-height: 280px;
                // margin: 150px auto 0;
                overflow: hidden;
                display: flex;
                justify-content: center;
                border-radius: 15px 15px 0 0;
                .img{
                    transition: all 0.6s ease 0s;
                    
                }
    
                .img:hover{
                    transform: scale(1.2);
                    /*transition: all 2s ease-in-out 0s;*/
                }
            }


            .text{
                font-weight: bold;
                font-size: 22px;
                padding: 18px;
            }
        }
        .box:hover{
            // -moz-box-shadow:0 0 10px #f0f0f0;
            // -webkit-box-shadow:0 0 10px #f0f0f0;
            box-shadow:0 0 15px #ccc;
            background: #fff;
            // border: 1px #f0f0f0 solid;
        }
    }
}
