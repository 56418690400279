// @import "./mixins.scss";
@import "antd/dist/reset.css";

$theme_color: #0457b7;
// $theme_color: #02489f;
// 

@media (min-width:1200px){
    .hidden-lg{display:none!important}
    .visible-lg-block{display:block!important}
    .visible-lg{display:inline!important}
    .visible-lg-inline-block{
        display:inline-block!important
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1300px!important;
    }
}

@media (max-width:767px){
    .hidden-xs{display:none!important}
    .visible-lg{display:none!important}
}
@media (min-width:768px) and (max-width:991px){
    .hidden-sm{display:none!important}
    .visible-lg{display:none!important}
}
@media (min-width:992px) and (max-width:1199px){
    .hidden-md{display:none!important}
    .visible-lg{display:none!important}
    
}




































.hide{
    display: none;
}
.necessary{
    color: red;
}
//modal
.at-modal__container {
    width: 654px;
}
.at-modal__header {
    text-align: left;
    font-family:Source Han Sans CN;
    font-weight:500;
    color:rgba(0,0,0,0.87);  
    padding: 40px;
    margin-top: 7px;

}
.at-modal__content {
    max-height: 1050px;
}
.modalbtn{
    height:84px;
    background:#6190E8;
    border-radius:8px;
    color:#fff;
    font-size:36px;
    font-family:Source Han Sans CN;
    font-weight:500;
    line-height: 84px;
}
//分割线
.dashed_box{
    position: relative;
    text-align: center;
    .dashed{
        position: absolute;
        top: 27px;
        border-top:1px dashed #999999;
        width: 100%;
        height: 1px;
        z-index: -1;
    }
    .text_box{
        background: #fff;
        z-index: 1;
        width: 256px;
        height: 40px;
        margin: 0 auto;
        .text{
            float: left;
            font-size:36px;
            font-family:Adobe Heiti Std;
            font-weight:normal;
            color:rgba(97,144,232,1);
        }
        .pic{
            float: left;
            width: 18px;
            height: 17px;
            margin: 20px;
        }
    }
    
}
//按钮
.Btn{ 
    border-radius: 10px;
    height:98px;
    line-height: 98px;
    color: #fff;
    background-color: #6190E8;
    font-size:32px;
    font-family:Source Han Sans CN;
    font-weight:500;            
}
.home__loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    position: relative;
    &-txt {
      color: #666;
      font-size: 24px;
    }
  }

$primary-color: #ffd330;
$disabled-color: #dddddd;
$hs:#1f2121;

$black: #000;
$white: #fff;
$whitef4: #f4f4f4;
$yellow: #ffd330;

$text-color: #fff;
$text-color-light: #666666;
$text-color-lighter: #999999;

$border-color: #dddddd;
$border-radius: 4px;

$image-bg: 'https://lg-gym.oss-cn-shenzhen.aliyuncs.com/wxfenx';