@import './styles/theme.scss';
.App {
  overflow-y:visible;
  height: 100vh;
  
  .header{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background: $theme_color;
    color: #fff;
    
    // height: 90px;
    .info{
      display: flex;
      justify-content: space-between;
      // width:1000px;
      height: 85px;
      .left{
        // padding: 25px 0;
        display: flex;
        align-items: center;
        .img{
          width: 36px;
          height: 36px;
          // display: none;
          margin-right: 5px;
        }
        .item{
          // padding-left: 10px;

        }
        // .item:first-child{
        //   // padding-right: 10px;
        //   // border-right: 1px #fff solid;
        // }
      }
      .right{
        display: flex;
        align-items: center;
        // padding: 15px;
        .right_item{
          display: flex;
          .item{
            padding: 15px;
            color: #4f96d3;
          }
          .item:hover,.itemac,.item:active{
            color: #fff;
            // font-weight: bold;
            text-decoration:none;
            padding: 15px;
          }
        }
        .switch{
          // background: $theme_color;
          padding: 12px 0;
        }

      }   

    }
    .infos{
      display: flex;
      justify-content: space-between;
      height: 85px;
      .left{
        // padding-left: 15px;
        padding: 25px 0;
        display: flex;
        align-items: center;
        .img{
          width: 36px;
          height: 36px;
          margin-right: 5px;
        }
        .item{
          // padding-left: 10px;
        }
      }
      .right{
        display: flex;
        // padding: 15px;
        align-items: center;
        .switch{
          // background: $theme_color;
          padding: 12px 0;
          text-align: center;
        }

      }   

    }
    
  }
  .Info_box{
    padding-top: 85px;
    min-height: calc(100vh - 108px);;
  }
  .Footer{
    padding: 30px;
    text-align: center;
    background: #fff;
    height: 108px;
  }
  // ant-drawer-body
  .right_items{
    // display: flex;
    background: red;
    text-align: center;
    .item{
      padding: 15px;
      color: #000;
    }
    .item:hover,.itemac,.item:active{
      color: #fff;
      // font-weight: bold;
      text-decoration:none;
      padding: 15px;
    }
  }
}
.item{
  // padding: 15px;
  // color: #000;
}
.item:hover,.itemac,.item:active{
  // color: $theme_color!important;
  // font-weight: bold;
  text-decoration:none;
  // padding: 15px;
  // background: $theme_color!important;
  border-bottom: 1px $theme_color solid!important;
  font-weight: bold;

}






@mixin banner--sm() { 
  .banner { 
    font-size: 20px; 
  } 
} 
@mixin banner--md() { 
  .banner { 
    text-align: left;
    font-size: 25px; 
  }
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

